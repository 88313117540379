import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import newRequest from "../../utils/newRequest";
import './CreativeReview.scss';
import emailjs from '@emailjs/browser';
import ReactPlayer from 'react-player';

const CreativeReview = () => {
  const { id } = useParams();
  const [submittedImages, setSubmittedImages] = useState([]);
  const [approvedImages, setApprovedImages] = useState([]);
  const [rejectedImages, setRejectedImages] = useState([]);
  const [submittedVideos, setSubmittedVideos] = useState([]);
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [rejectedVideos, setRejectedVideos] = useState([]);
  const [sellerId, setSellerId] = useState();
  const [buyerId, setBuyerId] = useState();
  const [sellersData, setSellersData] = useState({});
  const [buyersData, setBuyersData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await newRequest.get(`/projects/${id}`);
        const data = response.data;
        setSubmittedImages(data.submittedImages || []);
        setApprovedImages(data.approvedImages || []);
        setRejectedImages(data.rejectedImages || []);
        setSubmittedVideos(data.submittedVideo || []);
        setApprovedVideos(data.approvedVideo || []);
        setRejectedVideos(data.rejectedVideo || []);
        setSellerId(data.sellerId);
        setBuyerId(data.buyerId);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (sellerId && buyerId) {
      const fetchSellersAndBuyersData = async () => {
        try {
          const sellerRes = await newRequest.get(`/users/${sellerId}`);
          const buyerRes = await newRequest.get(`/users/${buyerId}`);

          setSellersData(sellerRes.data);
          setBuyersData(buyerRes.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchSellersAndBuyersData();
    }
  }, [sellerId, buyerId]);


  const handleApproveImage = async (url) => {
    const transformationPart = 'l_nkipwd8mhobct36zvujp,o_30,w_500,h_500/';
    const cleanUrl = url.replace(transformationPart, '');

   const updatedApprovedImages = [...approvedImages, cleanUrl];
    setApprovedImages(updatedApprovedImages);

    try {
      await newRequest.put(`/projects/${id}`, { approvedImages: updatedApprovedImages });
      await newRequest.delete(`/projects/${id}`, { data: { submittedImages: url } });
    } catch (err) {
      console.error("Error approving image", err);
    }
    const seller = sellersData;
    const buyer = buyersData;

    const templateParams = {
      to_email: buyer.email,
      from_email: seller.email,
      reviewUrl: `https://facefwd.ai/creativereview/${id}`,
      to_name: buyer.username,
      from_name: seller.username,
    };

    emailjs.send('service_grcioxh', 'template_c9fo58a', templateParams, {
      publicKey: 'SDCQ1BJyk-nqr804U',
    }).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        console.log('FAILED...', error);
      }
    );
  };

  const handleRejectImage = async (url) => {
    const updatedRejectedImages = [...rejectedImages, url];
    setRejectedImages(updatedRejectedImages);

    try {
      await newRequest.put(`/projects/${id}`, { rejectedImages: updatedRejectedImages });
      await newRequest.delete(`/projects/${id}`, { data: { submittedImages: url } });
    } catch (err) {
      console.error("Error rejecting image", err);
    }
  };

  const handleApproveVideo = async (title, url) => {
    const transformationPart = 'l_nkipwd8mhobct36zvujp,o_30,w_500,h_500/';
    const cleanUrl = url.replace(transformationPart, '');
  
    // Create an object in the "title: url" format for approved videos
    const newApprovedVideo = { [title]: cleanUrl };
    const updatedApprovedVideos = [...approvedVideos, newApprovedVideo];
    setApprovedVideos(updatedApprovedVideos);
  
    try {
      // Update the approvedVideos array on the server
      await newRequest.put(`/projects/${id}`, { approvedVideos: updatedApprovedVideos });
      // Remove the video from submittedVideos on the server
      await newRequest.delete(`/projects/${id}`, { data: { submittedVideo: { [title]: url } } });
    } catch (err) {
      console.error("Error approving video", err);
    }
    const seller = sellersData;
    const buyer = buyersData;

    const templateParams = {
      to_email: seller.email,
      from_email: buyer.email,
      reviewUrl: `https://facefwd.ai/project/${id}`,
      to_name: seller.username,
      from_name: buyer.username,
    };

    emailjs.send('service_grcioxh', 'template_c9fo58a', templateParams, {
      publicKey: 'SDCQ1BJyk-nqr804U',
    }).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        console.log('FAILED...', error);
      }
    );
  };
  
  const handleRejectVideo = async (title, url) => {
    // Create an object in the "title: url" format for rejected videos
    const newRejectedVideo = { [title]: url };
    const updatedRejectedVideos = [...rejectedVideos, newRejectedVideo];
    setRejectedVideos(updatedRejectedVideos);
  
    try {
      // Update the rejectedVideos array on the server
      await newRequest.put(`/projects/${id}`, { rejectedVideos: updatedRejectedVideos });
      // Remove the video from submittedVideos on the server
      await newRequest.delete(`/projects/${id}`, { data: { submittedVideo: { [title]: url } } });
    } catch (err) {
      console.error("Error rejecting video", err);
    }
  };

  return (
    <div className="creativeReview">
      <h1>Submitted, Approved, and Rejected Images and Videos</h1>
      <div className="row-container">
        <div className="right-container">
          {submittedImages.length > 0 && (
            <div className="image-result">
              <h2>Submitted Images:</h2>
              <div className="images-grid">
                {submittedImages.map((url, idx) => (
                  <div key={idx} className="image-card">
                    <img src={url} alt={`Submitted ${idx + 1}`} />
                    <div className="image-card-buttons">
                      <button onClick={() => handleApproveImage(url)}>Approve</button>
                      <button onClick={() => handleRejectImage(url)}>Reject</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {approvedImages.length > 0 && (
            <div className="image-result">
              <h2>Approved Images:</h2>
              <div className="images-grid">
                {approvedImages.map((url, idx) => (
                  <div key={idx} className="image-card">
                    <img src={url} alt={`Approved ${idx + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {rejectedImages.length > 0 && (
            <div className="image-result">
              <h2>Rejected Images:</h2>
              <div className="images-grid">
                {rejectedImages.map((url, idx) => (
                  <div key={idx} className="image-card">
                    <img src={url} alt={`Rejected ${idx + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {submittedVideos.length > 0 && (
  <div className="video-section">
    <h2>Submitted Videos:</h2>
    <div className="videos-grid">
      {submittedVideos.map((video, idx) => (
        Object.entries(video).map(([title, url]) => (
          title !== "_id" && (  // Exclude the _id key
            <div key={`${idx}-${title}`} className="video-container">
              <p className="video-title">{title}</p>
              <div className="video-card">
                <ReactPlayer
                  url={url}
                  controls
                  className="react-player"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload' // Attempts to disable download option
                      }
                    }
                  }}
                />
              </div>
              <div className="video-card-buttons">
                <button onClick={() => handleApproveVideo(title, url)}>Approve</button>
                <button onClick={() => handleRejectVideo(title, url)}>Reject</button>
              </div>
            </div>
          )
        ))
      ))}
    </div>
  </div>
)}

{approvedVideos.length > 0 && (
  <div className="video-section">
    <h2>Approved Videos:</h2>
    <div className="videos-grid">
      {approvedVideos.map((video, idx) => (
        Object.entries(video).map(([title, url]) => (
          title !== "_id" && (
            <div key={`${idx}-${title}`} className="video-container">
              <p className="video-title">{title}</p>
              <div className="video-card">
                <ReactPlayer
                  url={url}
                  controls
                  className="react-player"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />
              </div>
            </div>
          )
        ))
      ))}
    </div>
  </div>
)}

{rejectedVideos.length > 0 && (
  <div className="video-section">
    <h2>Rejected Videos:</h2>
    <div className="videos-grid">
      {rejectedVideos.map((video, idx) => (
        Object.entries(video).map(([title, url]) => (
          title !== "_id" && (
            <div key={`${idx}-${title}`} className="video-container">
              <p className="video-title">{title}</p>
              <div className="video-card">
                <ReactPlayer
                  url={url}
                  controls
                  className="react-player"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />
              </div>
            </div>
          )
        ))
      ))}
    </div>
  </div>
)}
        </div>
      </div>
    </div>
  );
};

export default CreativeReview;
