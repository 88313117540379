import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Orders.scss";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import emailjs from '@emailjs/browser';

const Orders = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [buyersData, setBuyersData] = useState({});
  const [sellersData, setSellersData] = useState({});
  const [projectsData, setProjectsData] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [expandedCampaigns, setExpandedCampaigns] = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: ["orders"],
    queryFn: () => newRequest.get(`/orders`).then((res) => res.data),
  });

  const completeMutation = useMutation({
    mutationFn: (id) => newRequest.put(`/orders/${id}/complete`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });

  const createProjectMutation = useMutation({
    mutationFn: (projectData) => newRequest.post(`/projects`, projectData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });

  const createReviewMutation = useMutation({
    mutationFn: (reviewData) => newRequest.post(`/creativereview`, reviewData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["creativereview"] });
    },
  });

  const updateOrderStatus = useMutation({
    mutationFn: ({ id, status }) => newRequest.put(`/orders/${id}`, { status }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });

  const updateProjectStatus = useMutation({
    mutationFn: ({ id, status, videoUrl }) => newRequest.put(`/projects/${id}`, { status, submittedVideo: videoUrl }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });

  const createCampaignMutation = useMutation({
    mutationFn: (newCampaign) => newRequest.post('/campaigns', newCampaign),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
    },
  });

  const fetchCampaigns = async () => {
    try {
      const res = await newRequest.get(`/campaigns`);
      setCampaigns(res.data);
    } catch (err) {
      console.error("Error fetching campaigns", err);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (data) {
      const fetchBuyerData = async () => {
        const buyers = await Promise.all(
          data.map(async (order) => {
            const buyer = await newRequest.get(`/users/${order.buyerId}`);
            return { buyerId: order.buyerId, buyer: buyer.data };
          })
        );

        const buyersMap = buyers.reduce((acc, curr) => {
          acc[curr.buyerId] = curr.buyer;
          return acc;
        }, {});
        setBuyersData(buyersMap);
      };

      fetchBuyerData();
    }
  }, [data, newRequest]);

  useEffect(() => {
    if (data) {
      const fetchSellerData = async () => {
        const sellers = await Promise.all(
          data.map(async (order) => {
            const seller = await newRequest.get(`/users/${order.sellerId}`);
            return { sellerId: order.sellerId, seller: seller.data };
          })
        );

        const sellersMap = sellers.reduce((acc, curr) => {
          acc[curr.sellerId] = curr.seller;
          return acc;
        }, {});
        setSellersData(sellersMap);
      };

      fetchSellerData();
    }
  }, [data, newRequest]);

  useEffect(() => {
    if (data) {
      const fetchProjectData = async () => {
        const projects = await Promise.all(
          data.map(async (order) => {
            const project = await newRequest.get(`/projects/${order._id}`);
            return { orderId: order._id, project: project.data };
          })
        );

        const projectsMap = projects.reduce((acc, curr) => {
          acc[curr.orderId] = curr.project;
          return acc;
        }, {});
        setProjectsData(projectsMap);
      };

      fetchProjectData();
    }
  }, [data, newRequest]);

  useEffect(() => {
    if (data) {
      const initialExpandedState = data.reduce((acc, order) => {
        const campaignId = order.campaignId || "uncategorized";
        if (!acc[campaignId]) {
          acc[campaignId] = true;
        }
        return acc;
      }, {});
      setExpandedCampaigns(initialExpandedState);
    }
  }, [data]);

  const handleContact = async (order) => {
    const sellerId = order.sellerId;
    const buyerId = order.buyerId;
    const id = sellerId + buyerId;

    try {
      const res = await newRequest.get(`/conversations/single/${id}`);
      navigate(`/message/${res.data.id}`);
    } catch (err) {
      if (err.response.status === 404) {
        const res = await newRequest.post(`/conversations/`, {
          to: currentUser.seller ? buyerId : sellerId,
        });
        navigate(`/message/${res.data.id}`);
      }
    }
  };

  const handleComplete = (id) => {
    completeMutation.mutate(id, {
      onSuccess: async (data) => {
        const order = data;
        const buyer = buyersData[order.buyerId];
        const seller = sellersData[order.sellerId];
  
        const templateParams = {
          to_email: currentUser.isSeller ? buyer.email : seller.email,
          from_email: currentUser.email,
          reviewUrl: `https://facefwd.ai/orders`,
          to_name: currentUser.isSeller ? buyer.username : seller.username,
          from_name: currentUser.username,
        };
  
        emailjs.send('service_grcioxh', 'template_a9pz586', templateParams, 'SDCQ1BJyk-nqr804U')
          .then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
              console.log('FAILED...', error);
            }
          );
      }
    });
  };

  const handleLaunchProject = async (order) => {
    try {
      await newRequest.get(`/projects/${order._id}`);
      navigate(`/project/${order._id}`);
    } catch (err) {
      if (err.response.status === 404) {
        const projectData = {
          orderId: order._id,
          title: order.title,
          status: order.status,
          sellerId: order.sellerId,
          buyerId: order.buyerId,
        };
        await createProjectMutation.mutateAsync(projectData);
        navigate(`/project/${order._id}`);
      }
    }
  };

  const handleLaunchReview = async (order) => {
    try {
      await newRequest.get(`/creativereview/${order._id}`);
      navigate(`/creativereview/${order._id}`);
    } catch (err) {
      if (err.response.status === 404) {
        const reviewData = {
          orderId: order._id,
          title: order.title,
          status: order.status,
          sellerId: order.sellerId,
          buyerId: order.buyerId,
        };
        await createReviewMutation.mutateAsync(reviewData);
        navigate(`/creativereview/${order._id}`);
      }
    }
  };

  const handleViewVideo = (videoUrl) => {
    window.open(videoUrl, "_blank");
  };

  const handleApproveVideo = async (order) => {
    try {
      const project = projectsData[order._id];
      if (project) {
        await updateProjectStatus.mutateAsync({
          id: project.orderId,
          status: "completed",
          videoUrl: project.submittedVideo,
        });
        await updateOrderStatus.mutateAsync({ id: order._id, status: "completed" });
      }
    } catch (err) {
      console.error("Error approving video", err);
    }
  };

  const handleCampaignSelect = async (orderId, campaignId) => {
    try {
      await newRequest.put(`/orders/${orderId}/campaign`, { campaignId });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    } catch (err) {
      console.error("Error updating campaign for order", err);
    }
  };

  const toggleCampaign = (campaignId) => {
    setExpandedCampaigns((prev) => ({
      ...prev,
      [campaignId]: !prev[campaignId],
    }));
  };

  const groupedOrders = (data || []).reduce((acc, order) => {
    const campaignId = order.campaignId || "uncategorized";
    if (!acc[campaignId]) {
      acc[campaignId] = [];
    }
    acc[campaignId].push(order);
    return acc;
  }, {});

  const calculateBudget = (orders, campaign) => {
    const totalBudget = campaign ? campaign.budget : 0;
    const workingBudget = orders.filter(order => order.status === 'in progress').reduce((sum, order) => sum + order.price, 0);
    const leftToSpend = totalBudget - workingBudget;
    return { totalBudget, workingBudget, leftToSpend };
  };

  const handleCreateCampaign = async () => {
    const campaignName = prompt("Enter campaign name:");
    const campaignBudget = parseFloat(prompt("Enter campaign budget:"));
    if (campaignName && campaignBudget) {
      createCampaignMutation.mutate({
        name: campaignName,
        budget: campaignBudget,
        buyerId: currentUser.id,
        company: currentUser.company,
      });
    }
  };

return (
  <div className="orders">
    {isLoading ? (
      "loading"
    ) : error ? (
      "error"
    ) : (
      <div className="container">
        <div className="title">
          <h1>Orders</h1>
          {!currentUser.isSeller && (
            <button onClick={handleCreateCampaign}>Create Campaign</button>
          )}
        </div>
        {Object.keys(groupedOrders).map((campaignId) => {
          const orders = groupedOrders[campaignId];
          const campaign = campaigns.find(c => c._id === campaignId);
          const { totalBudget, workingBudget, leftToSpend } = calculateBudget(orders, campaign);

          return (
            <div key={campaignId} className="campaign-section">
              {!currentUser.isSeller && (
                <div className="campaign-header" onClick={() => toggleCampaign(campaignId)}>
                  <h2>{campaign ? campaign.name : "Uncategorized"}</h2>
                  {campaign && (
                    <>
                      <p>Budget: {totalBudget}</p>
                      <p>Working Budget: {workingBudget}</p>
                      <p>Budget Left: {leftToSpend}</p>
                    </>
                  )}
                </div>
              )}
              {expandedCampaigns[campaignId] && (
                <table>
                  <thead>
                    <tr>
                      <th>Campaign</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Username</th>
                      <th>Price</th>
                      <th>Contact</th>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Complete Order</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => {
                      const project = projectsData[order._id];
                      return (
                        <tr key={order._id}>
                          <td>
                            {order.campaignId ? (
                              campaigns.find(c => c._id === order.campaignId)?.name || "loading..."
                            ) : (
                              <select
                                onChange={(e) => handleCampaignSelect(order._id, e.target.value)}
                              >
                                <option value="">Select Campaign</option>
                                {campaigns.map((campaign) => (
                                  <option key={campaign._id} value={campaign._id}>
                                    {campaign.name}
                                  </option>
                                ))}
                              </select>
                            )}
                          </td>
                          <td>
                            <img className="image" src={order.img} alt="" />
                          </td>
                          <td>{order.title}</td>
                          <td>
                            {currentUser.isSeller
                              ? buyersData[order.buyerId]?.username
                              : sellersData[order.sellerId]?.username || "loading..."}
                          </td>
                          <td>{order.price}</td>
                          <td>
                            <img
                              className="message"
                              src="./img/message.png"
                              alt=""
                              onClick={() => handleContact(order)}
                            />
                          </td>
                          <td>{order.status}</td>
                          <td>
                            {currentUser.isSeller ? (
                              <button onClick={() => handleLaunchReview(order)}>
                                Review Creative
                              </button>
                            ) : (
                              <button onClick={() => handleLaunchProject(order)}>
                                Create Project
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => handleComplete(order._id)}
                            >
                              Complete Order
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          );
        })}
      </div>
    )}
  </div>
);
};

export default Orders;
