import "./app.scss";
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";
import { React }from "react";
//import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
//import Home from "./pages/home/Home";
import Gigs from "./pages/gigs/Gigs";
import Gig from "./pages/gig/Gig";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Add from "./pages/add/Add";
import Orders from "./pages/orders/Orders";
import Messages from "./pages/messages/Messages";
import Message from "./pages/message/Message";
import MyGigs from "./pages/myGigs/MyGigs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Pay from "./pages/pay/Pay";
import Success from "./pages/success/Success";
import Stripe from "./pages/stripe/Stripe";
import ContactForm from "./pages/contact/Contact";
import About from "./pages/about/About";
import ReactGA from "react-ga4";
import Privacypolicy from "./pages/privacyPolicy/PrivacyPolicy";
import StripePay from "./pages/pay/Stripepay";
//import Project from "./pages/Project/project";
import Settings from "./pages/settings/Settings";
//import TryIt from "./pages/tryIt/TryIt";
import Onboard from "./pages/onboard/Onboard";
import HomePage from "./pages/HomePage/HomePage";
import { Navigation } from "./components/tempComps/tempComps/navigation";
import ImageRender from "./pages/imageRender/Imagerender";
import CreativeReview from "./pages/creativeReview/CreativeReview";
import AgencyDashboard from "./pages/agencyDashboard/AgencyDash";
import EditGig from "./pages/editGig/EditGig";
import ImageRenderItem from "./pages/imageRenderItem/ImagerenderItem";
import Criticalcontent1 from "./pages/criticalcontent1/Criticalcontent1";
//import Criticalcontent from "./pages/Criticalcontent/criticalcontent";

//import Facebook from "./components/facebookTracking/FacebookTracking"

//import HomePage from "./pages/HomePage/HomePage";


ReactGA.initialize('G-N92RQQTRD6')

function App() {
  const queryClient = new QueryClient();
  
  const Layout = () => {
    return (
      <div className="app">
        <QueryClientProvider client={queryClient}>
          <Navigation />
          <Outlet />
          <Footer />
        </QueryClientProvider>
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/gigs",
          element: <Gigs />,
        },
        {
          path: "/myGigs",
          element: <MyGigs />,
        },
        {
          path: "/orders",
          element: <Orders />,
        },
        {
          path: "/messages",
          element: <Messages />,
        },
        {
          path: "/message/:id",
          element: <Message />,
        },
        {
          path: "/add",
          element: <Add />,
        },
        {
          path: "/gig/:id",
          element: <Gig />,
        },
        {
          path: "/editgig/:id",
          element: <EditGig />,
        },
        {
          path: "/itemdemo/:id",
          element: <ImageRenderItem />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/stripe",
          element: <Stripe />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/pay/:id",
          element: <Pay />,
        },
        {
          path: "/success",
          element: <Success />,
        },
        {
          path: "/contact",
          element: <ContactForm />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/privacy",
          element: <Privacypolicy />,
        },
        {
          path: "/Stripepay",
          element: <StripePay />,
        },
        {
          path: "/project/:id",
          element: <ImageRenderItem />,
        },
        {
          path: "/creativereview/:id",
          element: <CreativeReview />,
        },
        {
          path: "/settings/:id",
          element: <Settings />,
        },{
          path: "/tryit/:id",
          element: <ImageRender />,
        },
        {
          path: "/imagerender",
          element: <ImageRender />,
        },{
          path: "/onboard",
          element: <Onboard />,
        },
        {
          path: "/agencydashboard",
          element: <AgencyDashboard />,
        },{
          path: "/criticalcontent1",
          element: <Criticalcontent1 />,
        },
       // {
        //  path: "/criticalcontent",
        //  element: <Criticalcontent />,
       // },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
