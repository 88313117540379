import React, { useState, useEffect } from "react";
import upload from "../../utils/upload";
import "./Onboard.scss";
import newRequest from "../../utils/newRequest";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import axios from "axios";

function Onboard() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [audioFile, setAudioFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    img: "",
    aIimages: [],
    audioFile: "",
    eleven11voiceId: "",
    country: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    linkedin: "",
    Youtube: "",
    twitter: "",
    isSeller: true,
    desc: "",
    managerEmail: "",
    referrerCode: "",
    aIdesc: "",
    agency:"",
    modelKey:"",
    modelPrompt:"",
    grayIds:"",
    finetuneKey:"",

  });
  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = ({ origin, data: eventObject }) => {
      if (origin !== "https://app.useanvil.com") return;
      if (eventObject && typeof eventObject === "object") {
        if (eventObject.action === "signerComplete") {
          setShowIframe(false);
          navigate("/login");
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);

  const buildIframeUrl = () => {
    const exampleData = {
      sellerName: {
        firstName: user.firstName,
        lastName: user.lastName,
      },
      sellerEmail: user.email,
      sellerAddress: {
        street1: user.address,
        city: user.city,
        state: user.state,
        zip: user.zipcode,
        country: user.country,
    }
    };
    const dParam = JSON.stringify(exampleData);
    const path = "https://app.useanvil.com/form/facefwd-llc/ffwcontractform-1?d=";
    return `${path}${encodeURIComponent(dParam)}`;
  };
 // useEffect(() => {
  //   {
   //   const url = buildIframeUrl();
   //   setIframeSrc(url);
   //   setShowIframe(true);
  //  }
 // }, [currentStep, user.isSeller]);

  const handleChange = (e) => {
    setUser((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSeller = (e) => {
    setUser((prev) => {
      return { ...prev, isSeller: e.target.checked };
    });
  };

  const templateParams = {
    newUsername: user.username,
    newUseremail: user.email,
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
    
      const cloudinaryUrls = await Promise.all(
        [...files].map(async (file) => {
          const photoUrl = await upload(file)
          return photoUrl;
        })
      );
      console.log('Cloudinary URLs:', cloudinaryUrls);

      const response = await axios.post('https://app.facefwd.ai/api/upload-and-catalog', {
        photoUrls: cloudinaryUrls,
        group_id: user.username,
        caption: user.desc
        
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const { uploadedImages, captions, item_ids } = response.data;
      console.log('Uploaded Images:', uploadedImages);
      console.log('Captions:', captions);
      console.log('Item IDs:', item_ids);
  
      let audioFileUrl = "";
      let eleven11voiceId = "";
  
      if (audioFile) {
        const audioFormData = new FormData();
        audioFormData.append("files", audioFile);
        audioFormData.append("name", user.username);
        audioFileUrl = await upload(audioFile);
  
        const elevenLabsResponse = await axios.post(
          "https://api.elevenlabs.io/v1/voices/add",
          audioFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "xi-api-key": `sk_e5d3a65d2ca406714e1c7a6c1609a07566d30d2f241dd5d4`,
            },
          }
        );
        eleven11voiceId = elevenLabsResponse.data.voice_id;
      }
  
      setUser((prev) => ({
        ...prev,
        audioFile: audioFileUrl,
        eleven11voiceId,
        aIimages: cloudinaryUrls,
      }));
    } catch (err) {
      console.error('Error during upload:', err);
    } finally {
      setUploading(false);
    }
  };


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    try {
      // Call handleUpload and wait for it to complete
      await handleUpload();
      const profileUrl = await upload(file);
      await newRequest
        .post("/auth/register", {
          ...user,
          img: profileUrl,
        })
        .then((res) => {
          console.log("res => ", res);
          localStorage.setItem("userId", res.data.userId);
        });
      emailjs.send(
        "service_grcioxh",
        "template_50kh99z",
        templateParams,
        { publicKey: "SDCQ1BJyk-nqr804U" }
      );
      
      if (user.isSeller) {
        const url = buildIframeUrl();  // Build the iframe URL here
        setIframeSrc(url);  // Set the iframe source
        setShowIframe(true);  // Show the iframe
      } else {
        navigate("/login"); // Navigate only if the user is not a seller
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="onboard">
      <form onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <div className="step">
            <h1>Step 1: Account Information</h1>
            <label htmlFor="">Username</label>
            <input
              name="username"
              type="text"
              placeholder="johndoe"
              onChange={handleChange}
            />
            <label htmlFor="">Email</label>
            <input
              name="email"
              type="email"
              placeholder="email"
              onChange={handleChange}
            />
            <label htmlFor="">Password</label>
            <input
              name="password"
              type="password"
              onChange={handleChange}
            />
             <label htmlFor="">Profile Picture</label>
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            <div className="toggle">
              <label htmlFor="">Activate a talent account</label>
              <label className="switch">
                <input type="checkbox" onChange={handleSeller} defaultChecked={true}/>
                <span className="slider round"></span>
              </label>
            </div>
            <button type="button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="step">
            <h1>Step 2: Personal Information</h1>
            <label htmlFor="">First Name</label>
            <input
              name="firstName"
              type="text"
              placeholder="john"
              onChange={handleChange}
            />
            <label htmlFor="">Last Name</label>
            <input
              name="lastName"
              type="text"
              placeholder="john"
              onChange={handleChange}
            />
            <label htmlFor="">Phone Number</label>
            <input
              name="phone"
              type="text"
              placeholder="+1 234 567 89"
              onChange={handleChange}
            />
            <label htmlFor="">Address</label>
            <input
              name="address"
              type="text"
              placeholder="123 facefwd pl"
              onChange={handleChange}
            />
            <label htmlFor="">City</label>
            <input
              name="city"
              type="text"
              placeholder="New York"
              onChange={handleChange}
            />
            <label htmlFor="">State</label>
            <input
              name="state"
              type="text"
              placeholder="New York"
              onChange={handleChange}
            />
            <label htmlFor="">Zipcode</label>
            <input
              name="zipcode"
              type="text"
              placeholder="123 facefwd pl"
              onChange={handleChange}
            />
            <label htmlFor="">Country</label>
            <select name="country" id="country" onChange={handleChange}>
            <option value="Afghanistan">Afghanistan</option>
    <option value="Åland Islands">Åland Islands</option>
    <option value="Albania">Albania</option>
    <option value="Algeria">Algeria</option>
    <option value="American Samoa">American Samoa</option>
    <option value="Andorra">Andorra</option>
    <option value="Angola">Angola</option>
    <option value="Anguilla">Anguilla</option>
    <option value="Antarctica">Antarctica</option>
    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
    <option value="Argentina">Argentina</option>
    <option value="Armenia">Armenia</option>
    <option value="Aruba">Aruba</option>
    <option value="Australia">Australia</option>
    <option value="Austria">Austria</option>
    <option value="Azerbaijan">Azerbaijan</option>
    <option value="Bahamas">Bahamas</option>
    <option value="Bahrain">Bahrain</option>
    <option value="Bangladesh">Bangladesh</option>
    <option value="Barbados">Barbados</option>
    <option value="Belarus">Belarus</option>
    <option value="Belgium">Belgium</option>
    <option value="Belize">Belize</option>
    <option value="Benin">Benin</option>
    <option value="Bermuda">Bermuda</option>
    <option value="Bhutan">Bhutan</option>
    <option value="Bolivia (Plurinational State of)">Bolivia (Plurinational State of)</option>
    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
    <option value="Botswana">Botswana</option>
    <option value="Bouvet Island">Bouvet Island</option>
    <option value="Brazil">Brazil</option>
    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
    <option value="Brunei Darussalam">Brunei Darussalam</option>
    <option value="Bulgaria">Bulgaria</option>
    <option value="Burkina Faso">Burkina Faso</option>
    <option value="Burundi">Burundi</option>
    <option value="Cabo Verde">Cabo Verde</option>
    <option value="Cambodia">Cambodia</option>
    <option value="Cameroon">Cameroon</option>
    <option value="Canada">Canada</option>
    <option value="Caribbean Netherlands">Caribbean Netherlands</option>
    <option value="Cayman Islands">Cayman Islands</option>
    <option value="Central African Republic">Central African Republic</option>
    <option value="Chad">Chad</option>
    <option value="Chile">Chile</option>
    <option value="China">China</option>
    <option value="Christmas Island">Christmas Island</option>
    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
    <option value="Colombia">Colombia</option>
    <option value="Comoros">Comoros</option>
    <option value="Congo">Congo</option>
    <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
    <option value="Cook Islands">Cook Islands</option>
    <option value="Costa Rica">Costa Rica</option>
    <option value="Croatia">Croatia</option>
    <option value="Cuba">Cuba</option>
    <option value="Curaçao">Curaçao</option>
    <option value="Cyprus">Cyprus</option>
    <option value="Czech Republic">Czech Republic</option>
    <option value="Côte d'Ivoire">Côte d'Ivoire</option>
    <option value="Denmark">Denmark</option>
    <option value="Djibouti">Djibouti</option>
    <option value="Dominica">Dominica</option>
    <option value="Dominican Republic">Dominican Republic</option>
    <option value="Ecuador">Ecuador</option>
    <option value="Egypt">Egypt</option>
    <option value="El Salvador">El Salvador</option>
    <option value="Equatorial Guinea">Equatorial Guinea</option>
    <option value="Eritrea">Eritrea</option>
    <option value="Estonia">Estonia</option>
    <option value="Eswatini (Swaziland)">Eswatini (Swaziland)</option>
    <option value="Ethiopia">Ethiopia</option>
    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
    <option value="Faroe Islands">Faroe Islands</option>
    <option value="Fiji">Fiji</option>
    <option value="Finland">Finland</option>
    <option value="France">France</option>
    <option value="French Guiana">French Guiana</option>
    <option value="French Polynesia">French Polynesia</option>
    <option value="French Southern Territories">French Southern Territories</option>
    <option value="Gabon">Gabon</option>
    <option value="Gambia">Gambia</option>
    <option value="Georgia">Georgia</option>
    <option value="Germany">Germany</option>
    <option value="Ghana">Ghana</option>
    <option value="Gibraltar">Gibraltar</option>
    <option value="Greece">Greece</option>
    <option value="Greenland">Greenland</option>
    <option value="Grenada">Grenada</option>
    <option value="Guadeloupe">Guadeloupe</option>
    <option value="Guam">Guam</option>
    <option value="Guatemala">Guatemala</option>
    <option value="Guernsey">Guernsey</option>
    <option value="Guinea">Guinea</option>
    <option value="Guinea-Bissau">Guinea-Bissau</option>
    <option value="Guyana">Guyana</option>
    <option value="Haiti">Haiti</option>
    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
    <option value="Honduras">Honduras</option>
    <option value="Hong Kong">Hong Kong</option>
    <option value="Hungary">Hungary</option>
    <option value="Iceland">Iceland</option>
    <option value="India">India</option>
    <option value="Indonesia">Indonesia</option>
    <option value="Iran">Iran</option>
    <option value="Iraq">Iraq</option>
    <option value="Ireland">Ireland</option>
    <option value="Isle of Man">Isle of Man</option>
    <option value="Israel">Israel</option>
    <option value="Italy">Italy</option>
    <option value="Jamaica">Jamaica</option>
    <option value="Japan">Japan</option>
    <option value="Jersey">Jersey</option>
    <option value="Jordan">Jordan</option>
    <option value="Kazakhstan">Kazakhstan</option>
    <option value="Kenya">Kenya</option>
    <option value="Kiribati">Kiribati</option>
    <option value="Korea, North">Korea, North</option>
    <option value="Korea, South">Korea, South</option>
    <option value="Kosovo">Kosovo</option>
    <option value="Kuwait">Kuwait</option>
    <option value="Kyrgyzstan">Kyrgyzstan</option>
    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
    <option value="Latvia">Latvia</option>
    <option value="Lebanon">Lebanon</option>
    <option value="Lesotho">Lesotho</option>
    <option value="Liberia">Liberia</option>
    <option value="Libya">Libya</option>
    <option value="Liechtenstein">Liechtenstein</option>
    <option value="Lithuania">Lithuania</option>
    <option value="Luxembourg">Luxembourg</option>
    <option value="Macao">Macao</option>
    <option value="Macedonia North">Macedonia North</option>
    <option value="Madagascar">Madagascar</option>
    <option value="Malawi">Malawi</option>
    <option value="Malaysia">Malaysia</option>
    <option value="Maldives">Maldives</option>
    <option value="Mali">Mali</option>
    <option value="Malta">Malta</option>
    <option value="Marshall Islands">Marshall Islands</option>
    <option value="Martinique">Martinique</option>
    <option value="Mauritania">Mauritania</option>
    <option value="Mauritius">Mauritius</option>
    <option value="Mayotte">Mayotte</option>
    <option value="Mexico">Mexico</option>
    <option value="Micronesia">Micronesia</option>
    <option value="Moldova">Moldova</option>
    <option value="Monaco">Monaco</option>
    <option value="Mongolia">Mongolia</option>
    <option value="Montenegro">Montenegro</option>
    <option value="Montserrat">Montserrat</option>
    <option value="Morocco">Morocco</option>
    <option value="Mozambique">Mozambique</option>
    <option value="Myanmar (Burma)">Myanmar (Burma)</option>
    <option value="Namibia">Namibia</option>
    <option value="Nauru">Nauru</option>
    <option value="Nepal">Nepal</option>
    <option value="Netherlands">Netherlands</option>
    <option value="Netherlands Antilles">Netherlands Antilles</option>
    <option value="New Caledonia">New Caledonia</option>
    <option value="New Zealand">New Zealand</option>
    <option value="Nicaragua">Nicaragua</option>
    <option value="Niger">Niger</option>
    <option value="Nigeria">Nigeria</option>
    <option value="Niue">Niue</option>
    <option value="Norfolk Island">Norfolk Island</option>
    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
    <option value="Norway">Norway</option>
    <option value="Oman">Oman</option>
    <option value="Pakistan">Pakistan</option>
    <option value="Palau">Palau</option>
    <option value="Palestine">Palestine</option>
    <option value="Panama">Panama</option>
    <option value="Papua New Guinea">Papua New Guinea</option>
    <option value="Paraguay">Paraguay</option>
    <option value="Peru">Peru</option>
    <option value="Philippines">Philippines</option>
    <option value="Pitcairn Islands">Pitcairn Islands</option>
    <option value="Poland">Poland</option>
    <option value="Portugal">Portugal</option>
    <option value="Puerto Rico">Puerto Rico</option>
    <option value="Qatar">Qatar</option>
    <option value="Reunion">Reunion</option>
    <option value="Romania">Romania</option>
    <option value="Russian Federation">Russian Federation</option>
    <option value="Rwanda">Rwanda</option>
    <option value="Saint Barthelemy">Saint Barthelemy</option>
    <option value="Saint Helena">Saint Helena</option>
    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
    <option value="Saint Lucia">Saint Lucia</option>
    <option value="Saint Martin">Saint Martin</option>
    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
    <option value="Samoa">Samoa</option>
    <option value="San Marino">San Marino</option>
    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
    <option value="Saudi Arabia">Saudi Arabia</option>
    <option value="Senegal">Senegal</option>
    <option value="Serbia">Serbia</option>
    <option value="Serbia and Montenegro">Serbia and Montenegro</option>
    <option value="Seychelles">Seychelles</option>
    <option value="Sierra Leone">Sierra Leone</option>
    <option value="Singapore">Singapore</option>
    <option value="Sint Maarten">Sint Maarten</option>
    <option value="Slovakia">Slovakia</option>
    <option value="Slovenia">Slovenia</option>
    <option value="Solomon Islands">Solomon Islands</option>
    <option value="Somalia">Somalia</option>
    <option value="South Africa">South Africa</option>
    <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
    <option value="South Sudan">South Sudan</option>
    <option value="Spain">Spain</option>
    <option value="Sri Lanka">Sri Lanka</option>
    <option value="Sudan">Sudan</option>
    <option value="Suriname">Suriname</option>
    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
    <option value="Sweden">Sweden</option>
    <option value="Switzerland">Switzerland</option>
    <option value="Syria">Syria</option>
    <option value="Taiwan">Taiwan</option>
    <option value="Tajikistan">Tajikistan</option>
    <option value="Tanzania">Tanzania</option>
    <option value="Thailand">Thailand</option>
    <option value="Timor-Leste">Timor-Leste</option>
    <option value="Togo">Togo</option>
    <option value="Tokelau">Tokelau</option>
    <option value="Tonga">Tonga</option>
    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
    <option value="Tunisia">Tunisia</option>
    <option value="Turkey (Türkiye)">Turkey (Türkiye)</option>
    <option value="Turkmenistan">Turkmenistan</option>
    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
    <option value="Tuvalu">Tuvalu</option>
    <option value="U.S. Outlying Islands">U.S. Outlying Islands</option>
    <option value="Uganda">Uganda</option>
    <option value="Ukraine">Ukraine</option>
    <option value="United Arab Emirates">United Arab Emirates</option>
    <option value="United Kingdom">United Kingdom</option>
    <option value="United States">United States</option>
    <option value="Uruguay">Uruguay</option>
    <option value="Uzbekistan">Uzbekistan</option>
    <option value="Vanuatu">Vanuatu</option>
    <option value="Vatican City Holy See">Vatican City Holy See</option>
    <option value="Venezuela">Venezuela</option>
    <option value="Vietnam">Vietnam</option>
    <option value="Virgin Islands, British">Virgin Islands, British</option>
    <option value="Virgin Islands, U.S">Virgin Islands, U.S</option>
    <option value="Wallis and Futuna">Wallis and Futuna</option>
    <option value="Western Sahara">Western Sahara</option>
    <option value="Yemen">Yemen</option>
    <option value="Zambia">Zambia</option>
    <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <div className="actions">
              <button type="button" onClick={prevStep}>
                Previous
              </button>
              {user.isSeller ? (
                <button type="button" onClick={nextStep}>
                  Next
                </button>
              ) : (
                <button type="submit">Submit</button>
              )}
            </div>
          </div>
        )}

        {currentStep ===   3 && user.isSeller && (
          <div className="step">
            <h1>Step 3: Talent Account Information</h1>
            
            <label htmlFor="">Description</label>
            <textarea
              placeholder="A 35 year old white man with dirty blonde hair an blue eyes"
              name="desc"
              id=""
              cols="30"
              rows="10"
              onChange={handleChange}
            ></textarea>
            <label htmlFor="">Manager Email</label>
            <input
              name="managerEmail"
              type="text"
              placeholder=""
              onChange={handleChange}
            />
            <label htmlFor="">Agency</label>
            <input
              name="agency"
              type="text"
              placeholder="if you are represented"
              onChange={handleChange}
            />
            <label htmlFor="">Referrer Code</label>
            <input
              name="referrerCode"
              type="text"
              placeholder=""
              onChange={handleChange}
            />
            <button type="button" onClick={prevStep}>
              Previous
            </button>
            <button type="button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

{currentStep === 4 && user.isSeller && (
          <div className="step">
            <h1>Step 4: Social Links</h1>
            <label htmlFor="">Facebook</label>
            <input
              name="facebook"
              type="text"
              placeholder="Facebook profile link"
              onChange={handleChange}
            />
            <label htmlFor="">Instagram</label>
            <input
              name="instagram"
              type="text"
              placeholder="Instagram profile link"
              onChange={handleChange}
            />
            <label htmlFor="">TikTok</label>
            <input
              name="tiktok"
              type="text"
              placeholder="TikTok account link"
              onChange={handleChange}
            />
            <label htmlFor="">LinkedIn</label>
            <input
              name="linkedin"
              type="text"
              placeholder="LinkedIn profile link"
              onChange={handleChange}
            />
            <label htmlFor="">Youtube</label>
            <input
              name="Youtube"
              type="text"
              placeholder="Youtube page link"
              onChange={handleChange}
            />
            <label htmlFor="">Twitter</label>
            <input
              name="twitter"
              type="text"
              placeholder="twitter page link"
              onChange={handleChange}
            />
            <button type="button" onClick={prevStep}>
              Previous
            </button>
            <button type="button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}
        {currentStep === 5 && user.isSeller && (
          <div className="step">
            <h1>Step 5: Media Upload</h1>
            <label htmlFor="">Upload AI Images</label>
            <h2>Upload the images that will be used to train your digital twin, we reccommend (6) six full-body shots (10) ten chest up and (10) closeups for the best results.</h2>
            <input
              type="file"
              multiple
              onChange={(e) => setFiles(e.target.files)}
            />
            <label htmlFor="">Upload Audio File</label>
            <h2>Upload a 30 second audio sample of your voice. Make sure to use tones and verbaige that would be typical of your content.</h2>
            <input
              type="file"
              onChange={(e) => setAudioFile(e.target.files[0])}
            />
            {/*<button type="button" onClick={handleUpload}>
              {uploading ? "Uploading" : "Upload"}
        </button>*/}
            <button type="button" onClick={prevStep}>
              Previous
            </button>
            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}

{/*{currentStep === 6 && user.isSeller && (
  <div className="step">
    <h1>Step 6: Sign the Contract</h1>
    {showIframe ? (
      <div className="iframe-container">
        <iframe src={iframeSrc} title="e-signature" width="100%" height="500px"></iframe>
      </div>
    ) : (
      <>
        <button type="button" onClick={prevStep}>
          Previous
        </button>
        <button type="submit" onClick={handleSubmit}>
          Submit
        </button>
      </>
    )}
    </div>
    )}*/}
      </form>
      {showIframe && (
        <div className="iframe-container">
        <iframe src={iframeSrc} title="e-signature" width="100%" height="500px"></iframe>
      </div>
      )}
    </div>
  );
}

export default Onboard;
